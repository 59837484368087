import React from 'react'
import { Box } from "@mui/material";
import { MainHeader, MainTextCymru } from './Styles.jsx';

export default function PartOne() {
  return (
    <>
  <Box pl={4} pt={4} pr={4} sx={{ fontSize: "25px" }}>
    <MainHeader>
    Part One: In Which the Blemished are Banished
    </MainHeader>
  <hr />
  </Box>

  <Box pl={4} pb={2} pr={4} sx={{ fontSize: "16px" }}>
<MainTextCymru>
  I was hard at work popping two Quantum IV pimples when I heard Hottdóttirish hobnails on hardwood. It was Katrin Hottdóttir in her ear-shredding hobnail boots, her laddered tights and ill-faced tortoiseshell specs clomping towards me with an air of pleasant menace. Her thin chapped lips were about to mouth words pre-mouthed two minutes thence from the managerial menagerie—Aron Bergþóruson, Gunnar Bergþóruson, and Viktor Bergþóruson—the beaming brotherhood of The Húsavík Research Hive, where I worked as a pimple-popping basement operative unsackable because of laws.
</MainTextCymru>

<MainTextCymru>
  “Hey, Magnus. We’re having a cake moment in the vestibule,” she said. I spied a Battenberg crumb on her lower lip. I wanted to munch Battenberg in the vestibule. However, I suffered from a level of acne known to dermatologists as “post-post-nuclear”, so I could not munch. I had a lunar face populated with a series of inverted craters, like an exploding moon. The once smooth pink skin of childhood had been blitzed by little ball bearings that itched and bled and wept pus from time to time, usually whenever I wanted to interact with another human being, and I had to wear four layers of makeup to appear presentable. I wanted to eat Battenberg in the vestibule, however, The Húsavík Research Hive—a cornerstone of the New Icelandic commitment to appearing young and sexually irresistible at all times—frowned on the unsexable mutations of genetic mishaps, and kept us from view as reminders of a past of inbreeding. “It is constitutionally inappropriate, Katrin, that I should eat cake,” I replied.
</MainTextCymru>
<MainTextCymru>
  “What?”
</MainTextCymru>
<MainTextCymru>
  “I’ll pass.”
</MainTextCymru>
<MainTextCymru>
  “The brotherhood would like a word,” she added.
</MainTextCymru>
<MainTextCymru>
  “Now?”
</MainTextCymru>
<MainTextCymru>
  I had spent the morning as usual thinking up schemes to make Iceland appear more smug on the world stage. I took an hour writing a report on the influence of Icelandic avant-pop literature on the next wave of <i>New Yorker</i> writers, then an hour teasing the pus from a Quantum IV (I might explain the various pimple classifications later), to bring the altitude to a manageable level, and with a little blusher, reduce the beast to a Quantum II. I took the backstairs to the elevator, up to the top floor bubble-office that the brothers shared—a conceptual pimple on the smirking mug of the Research Hive.
</MainTextCymru>
<MainTextCymru>
  “Magnus! A proposition?” Head One said. The Bergþóruson brothers were a three-headed enigma of cocoa-brown backcombing and designer pince-nez. I perched on the edge of a bubble chair, angled at their preferred 42º.
</MainTextCymru>
<MainTextCymru>
  “Sure.”
</MainTextCymru>
<MainTextCymru>
  The Húsavík Research Hive was formed following Iceland’s successful transformation of Brunei from a self-serving sultanate into a collectivist Viking monoculture in order to fatten our influence in corrupt outcrops. Since the last two pandemoniums in America and Europe, most war-whomped nations sought workable solutions to help postpone the next one. Iceland had produced a catalogue—<i>Bloodbath Alternatives: Building Your Country’s Wealth Without Butchery</i>. The Hive was formed as part of an award-winning PR assault on the world, showcasing Iceland as the most vibrant and arousing nation unbothered with slime-ridden autocrats and sex pests. I was hired to help scheme up further means of larding Viking love into the Earth’s pores after I submitted several notions to the Bergþórusons, including the introduction of webcams in the echelons of power, so the public could hear what their leaders were scheming, removing all distrust between voter and politician. I was co-opted into the Hive in spite of the frothing tumult of acne on my otherwise exceptionally acceptable face, and allowed an office. Having solved the bulk of the world’s ecological and political problems with the simple rubric of not behaving like insane nitwits, The Hive concentrated on a range of innovative nonstick notions such as installing tip-meters next to the homeless to prevent passersby from having to lean over to deposit coins in a cup; installing chip pans with a heat-sensor that alerted the fire brigade to an unacceptable temperature in someone’s kitchen; and a special silencer on a bathroom hand drier to prevent unwanted ear damage in the drying of one’s mitts.
</MainTextCymru>
<MainTextCymru>
  “We have appraised your cerebral transmissions and think you are the right person to helm our new Cultural Excavation Exhibition,” Head Two said. “As we know, the war between Liechtenstein and Wales was catastrophic for the United Kingdom. The country was carpet-bombed and pumped with bullets and smashed up something bad. All traces of the former cultural life there have vanished from world awareness.”
</MainTextCymru>
<MainTextCymru>
  “Yes, it was in the news.”
</MainTextCymru>
<MainTextCymru>
“And they had some problems with chemical leaks and weird mutations and stuff.”
</MainTextCymru>
<MainTextCymru>
“Yes. News.”
</MainTextCymru>
<MainTextCymru>
  “This is an unmissable opportunity for Iceland to shape the nascent culture of the ravaged United Kingdom,” said Head Three. “It is probable that Iceland will colonise that country shortly, after Timor-Leste, Bulgaria, and Comoros, so we would like someone to bring us reports on the Welsh people—their culture, their beliefs, their Weltanschauungs—to help smooth our Icelandic-British alliance and herald in the new era of that nation with us in charge. This is, of course, several years away.”
</MainTextCymru>
<MainTextCymru>
  I had to smile (although smiling made my philtric pimple poke into my right nostril, and emerge with an unlovely sheen of cold mucus, so I never smiled). This was a diplomatic deportation strategy.
</MainTextCymru>
<MainTextCymru>
  “Continue,” I invited.
</MainTextCymru>
<MainTextCymru>
  “We would like you to collect information and compile reports on every village or town you visit. We are asking you to bumblebee one of the most significant tasks this hive has ever buzzed. As you know, it is our mission to spread the warm butter of Iceland across the ciabatta of the universe, from our folk dancing, our novels about sheep farmers, and our sense of resentful yet industrious binding of community. You will continue this fine tradition across your long and exhaustive travels.”
</MainTextCymru>
<MainTextCymru>
  “Clever. You have found a means at last of oust.”
</MainTextCymru>
<MainTextCymru>
  “Pardon?”
</MainTextCymru>
<MainTextCymru>
  “I understand. I am a son of a son of a son of a Magnussonson. I have inherited what has been termed ‘contrasocially problemsome facial grievances’, or as I prefer, ‘a fuckhaul of purulent spots and pimples that seep hard’. My ancestors, in particular my grandfather’s grandfather squared, or if you prefer, Magnussonson<sup>4</sup>, suffered from one of the most legendary blemishes in Icelandic history. His infamous boil, Popocatepetl, began on his fat chin, and over the course of a lifetime, subsumed his entire visage, so that when he turned fifty, he was little more than a boil-headed freakshow as snoggable as The Elephant Man. He was condemned to view the suppurating inner world of his head-boil, making long, haunting moans from a mouth inflummoxed with über-rasped skin. Having missed his chance to commit suicide, clinging for decades to the hope that a prominent surgeon might rescue him from his in-fallen face, he was sentenced to walking around blind, unable to locate bridges from which to leap. I have glommed some of that particular genetic repugnance, and you can’t tolerate this in your sexy orb.”
</MainTextCymru>
<MainTextCymru>
  “This is not a discrimination thing,” Head ??? said.
</MainTextCymru>
<MainTextCymru>
  After a few months, the Icelandic Dream had a strong Aryan whiff. Organisations began to remove their blemished from view, pushing us into basement offices, sealed cubicles, and outbuildings several hectares from the site. At The Hive, I was the relocated to subterranean premises with a wall-mounted sink. Next came Kristin Harr, with the unwaxable upper lip, a victim of a rare hormone imbalance that caused an hourly reprise of her partial moustache. No one wished to see such feminine hair over their lunchtime bowl of kimchi noodlettes on flatbread. Then followed Michael Growther, cursed with a reverse hump protruding from his left adenoid, and Salman Victor, with an outbending foot that tripped up runners and pizza people, and Lorna Oost, an albino with a purplish pigment in her right eyeball, and Chad Tee, an unattractive man with a clavicle wart. This sad persecution of the unpretty unlolled like a corpse’s tongue into the stinking fartbucket of the conscience-free Icelandic populous.
</MainTextCymru>
<MainTextCymru>
  “So my eminently reasonable assertion that a hereditary skin condition that even the loftiest quacks of Icelandic medicine cannot treat is somehow in contra with our new airbrushed image is complete nonsense?”
</MainTextCymru>
<MainTextCymru>
  The Bergþórusons were silent for a moment.
</MainTextCymru>
<MainTextCymru>
  “We think this will be a nice experience for you. It might help uncrease some of the vulgarities in your character. Take your lady with you to Wales. All expenses paid. Have fun, Magnus. Email us your reports.”
</MainTextCymru>
<MainTextCymru>
  I hurled pants and unguents into a suitcase. I saw my cat Lionel Shriver off to the cattery. I convinced my rancid girlfriend Katrin to come along. I went to Wales, I bummed around Wales, I came back from Wales, and I handed in my “reports”, in alphabetical order, to my paymasters.
</MainTextCymru>
  </Box>
  </>
  )
}
